import React from 'react'
import { vw, paths, patterns, genID} from '../utilities/helpers'
import SEO from '../utilities/seo'
import { graphql } from 'gatsby'

import Block from '../components/block'
import Grid from '../components/grid'
import Bit from '../components/bit'
import Glow from '../components/glow'
import Preface from '../components/preface'
import Next from '../components/next'
import Parallax from '../components/parallax'
import Hero from '../components/hero'
import Body from '../components/wrap'
import Footer from '../components/footer'

const id = genID()

function renderPreface(content, index) {
    const item = content.node.data
   
    return (
        <Block key={index} className="page-preface">
            <svg viewBox="0 0 1320 720" className="page-preface__image">
                <defs>
                    <linearGradient id={id+'f'} gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                        <stop offset="0" stopColor="#fff" />
                        <stop offset="1" stopColor="#000" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id={id+'g'} gradientUnits="objectBoundingBox" gradientTransform="rotate(90)">
                        <stop offset="0" stopColor="#4744E5" />
                        <stop offset="1" stopColor="#000" stopOpacity="0" />
                    </linearGradient>
                    <mask id={id + 'm'}>
                        <path fill={'url(#'+id+'f)'} x="100px" d={paths.c} />
                    </mask>
                </defs>
                <path fill={'url(#'+id+'g)'} d={paths.j} />
                <g mask={'url(#'+id+'m)'}>
                    <image xlinkHref={item.image.url} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                </g>
            </svg>
            <Hero right={vw(2)} top={vw(3)}>
              <img src={item.foreground.url} alt={item.heading.text} />
            </Hero>

            <Preface
                title={item.heading.text}

                summary={item.preface.text}
            />
            

            <Glow royal top="-15%" left={vw(-4)} delay="0" />
            <Bit cross top="75%" right="10%" />
            <Bit arrow top="45%" right="35%" />

            <Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
            <Bit zig top="55%" right="35%" />
        </Block>
    )
}

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export default ({data}) => {

    const speakers = data.allPrismicSpeaker.edges
    var speakerList = []

    for (var i = 0; i < speakers.length; i++){
      const node = speakers[i].node.data
      var sessions = ''
      var subtitle = ''

      if(node.sessions !== null){
        sessions = node.sessions.html
      }

      if(node.organization.text != null){
        subtitle = node.title.text + ', '+node.organization.text
      } else {
        subtitle = node.title.text
      }
      
      speakerList[i] = {
        'fg': node.photo.url,
        'title': node.name.text,
        'subtitle': subtitle,
        'bg': patterns.green,
        'organization': node.organization.text,
        'bio': node.bio.html,
        'sessions': sessions,
        'key': i
      }
    }

    shuffle(speakerList)

    return(
      <>
      <main>    
        <SEO description={data.allPrismicPage.edges[0].node.data.preface.text} title={data.allPrismicPage.edges[0].node.data.label.text} image={data.allPrismicPage.edges[0].node.data.image.url} />

        {data.allPrismicPage.edges.map(renderPreface)}

        <Block flex='true'>

            <Glow royal top="-5%" left={vw(-4)} delay="0" />
            <Bit arrow top="15%" left="15%" />

            <Glow cool top="15%" right={vw(-6)} delay="0.75" />
            <Bit zig top="15%" right="15%" />

            <Glow warm top="20%" left={vw(-6)} delay="1" />
            <Bit cross top="50%" left="15%" />

            <Glow warm bottom="-25%" right={vw(-4)} delay="1.25" />
            <Bit cross top="100%" right="15%" />

            <Body>
                <Grid col={4} cells={speakerList} />
            </Body>
        </Block>

        <Parallax c/>

        <Next to="/exhibition" title="Exhibition" />     
    </main>
      <Footer />
      </>
    )
}

export const query = graphql`
query Speakers {
  allPrismicPage(filter: {uid: {eq: "speakers"}}) {
    edges {
      node {
        data {
          preface {
            text
          }
          label {
            text
          }
          image {
            url
          }
          foreground{
            url
          }
          heading {
            text
          }
        }
        uid
      }
    }
  }
  allPrismicSpeaker {
    edges {
      node {
        data {
          bio {
            html
          }
          name {
            text
          }
          organization {
            text
          }
          photo {
            url
          }
          title {
            text
          }
          sessions {
            html
          }
        }
      }
    }
  }
}        
`